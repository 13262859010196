import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/aco/Banner.webp';
import Img1 from '../assets/images/aco/Img1.webp';
import HeartLogo from '../assets/images/aboutus/heart.webp';
import MiddleBanner from '../components/common/MiddleBanner';

const ACO = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Accountable Care Organization (ACO)  - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-4">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Accountable Care Organization
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          (ACO)
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Madison Primary Care 
            <span className="text-ss-primary"> (ACO) </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-center'>
            Madison Primary Care is a part of the Crestwood Regional Healthcare Alliance, an accountable care organization (ACO) dedicated to enhancing the quality and experience of your healthcare journey. ACOs, comprised of doctors, hospitals, and other healthcare professionals, collaborate to optimize care delivery and reduce costs, with the goal of meeting quality benchmarks. Medicare assesses ACO performance annually, rewarding those achieving excellence or conversely imposing penalties.
            <div className="py-2"/>
            Our ACO participation does not restrict your choice of healthcare providers or alter your Medicare benefits. You retain the freedom to access any Medicare-accepting provider at your convenience.  
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
            <div className='pt-2'/>
        <div className='fs-ss-35 fw-700 lh-sm'>
        How do <br />
        <span className='text-ss-primary'> ACOs work? </span>
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        ACOs function independently of Medicare Advantage plans or insurance schemes. Instead, they assume responsibility for care quality, cost efficiency, and patient experience. Coordinated care within ACOs streamlines healthcare processes, potentially reducing redundant tests and unnecessary appointments. Additionally, it facilitates early detection of issues like drug interactions, enhancing patient safety. 
        <div className="py-2"/>
        ACOs employ various tools, such as electronic health records and case management, to promote wellness and manage chronic conditions. Optional programs, like care management teams, encourage patient engagement and proactive healthcare. 
        {/* <div className="py-2"/>
        Participating practitioners in our ACO offer telehealth services, enabling remote consultations for patients irrespective of location. This innovative approach enhances accessibility to essential healthcare services and fosters real-time communication with healthcare professionals.  */}
        </div>
        </div>
        </div>
        <div className="col-8 col-lg-5 text-center px-lg-5">
        <img className='w-100' src={Img1} alt="" />
        </div>
      </div>
      </section>

    <section className='pt-5 mt-lg-5'/>

    <MiddleBanner/>

    <section className='pt-5 mt-lg-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
             What information <br className='' /> 
            <span className="text-ss-primary"> will be shared about me? </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para'>
             At Medicare Primary Care, we share information about your healthcare providers, such as dates and times you visited a healthcare provider, your medical conditions and illnesses, and a list of past and current prescriptions. This information helps Madison Primary Care track the care and tests that you’ve already had.   
            <div className="py-2"/>
            Medicare shares pertinent information with your healthcare providers, including visit details, medical history, and medication records. This collaborative exchange ensures seamless continuity of care and informed decision-making. 
            <div className="py-2"/>
             Sharing your data helps ensure all the providers involved in your care have access to your health information when and where they need it.  
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>
{/* 
    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">
      <div className="col-8 col-lg-5 text-center px-lg-5 d-none d-md-block">
        <img className='w-100' src={Img1} alt="" />
        </div>
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
            <div className='pt-2'/>
        <div className='fs-ss-35 fw-700 lh-sm'>
        CCM Supports <br />
        <span className='text-ss-primary'> Your Practice and Providers:  </span>
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        The Centers for Medicare & Medicaid Services (CMS) acknowledges the time and effort involved in providing CCM services and establishing programs to benefit patients and Medicare providers. Ongoing care management activities offer additional resources to assist practices caring for high-risk patients. Reimbursements for CCM assist practices in reducing administrative costs and provider burnout. CCM addresses the administrative burden of providing quality patient care and reducing adverse health outcomes, thus improving coordination with home and community-based clinical service providers and care team specialists. Improved health outcomes and patient satisfaction are achievable through chronic care management. Enhanced patient compliance and trust in the care team, improved efficiency, and reduced hospitalization and emergency department visits. 
        </div>
        </div>
        </div>
        <div className="col-8 col-lg-5 text-center px-lg-5 d-block d-md-none">
        <img className='w-100' src={Img1} alt="" />
        </div>
      </div>
      </section> */}

    <Footer/>
    </>
  );
}

export default ACO;
