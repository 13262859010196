import React, {useEffect} from 'react';
import { ArrowRight, EnvelopeFill, TelephoneFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const OneClinicalTeam = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
        <Link className="col-5 col-md-3 text-decoration-none text-ss-secondary text-center shadow-ss-1 px-0 mx-md-4 mx-1 r-t" data-bs-toggle="modal" data-bs-target={props.BackDrop1}>
          <img src={props.ProfileImg} alt="" className='w-100 px-0' />
          <div className="fs-ss-16 fw-400 lh-base py-4">
          <div className='fw-600 fs-ss-18 lh-sm'>  {props.Title1} {props.Title2} </div>
          <div className='fw-400 fs-ss-16 text-ss-primary'> {props.Title3} </div>
        </div>
        </Link>
        


<div className="modal modal-lg fade pb-4" id={props.BackDrop2} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-scrollable">
    <div className="modal-content bg-grey gy-0">
      <div className="modal-header">
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body row justify-content-evenly align-items-start gy-4 pb-5">
        <div className="col-7 col-md-3 rounded-4 shadow-ss-1 px-0">
        <img className="w-100" src={props.ProfileImg} alt="Dental General Practitioner" />
        </div>
        <div className="col-11 col-md-7 lh-sm">
        <div className="text-ss-primary fw-600 fs-ss-20 text-center text-md-start"> {props.Title1}
        <span className="fs-ss-20 fw-600 text-center text-md-start text-dark"> {props.Title2} </span> </div>
        <div className='fw-400 fs-ss-16 text-ss-primary pt-2'> {props.Title3} </div>
        <div className="fs-ss-15 fw-500 pt-4">
        <div className='mb-2'>
            <TelephoneFill className='text-ss-secondary me-2'/> 
            <a href="tel:+12567745524" target='_blank' className='text-decoration-none text-ss-secondary'>
            256-774-5524
            </a>
          </div>
          <div className='mb-2'>
            <EnvelopeFill className='text-ss-secondary me-2'/> 
            <a href="mailto:info@madisonprimarycare.com" target='_blank' className='text-decoration-none text-ss-secondary'>
            info@madisonprimarycare.com
            </a>
          </div>
        </div>
        <div className="text-ss-secondary fw-400 fs-ss-14 col-12 pt-4 lh-para ">
          {props.Para}
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </>
  );
}

export default OneClinicalTeam;
