import React from 'react';
import {Link} from 'react-router-dom';

const MiddleBanner = (props) => {
  return (
    <>
    <section id='website-responsive' className="container-fluid bg-middle-banner py-5">
      <div className="row justify-content-center text-center py-lg-5">
        <div className="col-11 col-lg-9">
          <div className='fw-700 text-white fs-ss-52 text-uppercase lh-sm'>
           Accepting New Patients 
          </div>
          <div className="text-white py-4 pt-lg-2 pb-lg-5 fs-ss-22 lh-sm fw-400">
            Call us at <a href="tel:+12567745524" className='text-decoration-none text-white fw-600'> 256-774-5524 </a>
            and let us take care of the rest
          </div>
            <div className=''>
            <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-green text-white border-0 fs-ss-16 px-4 rounded-5'>
              New Patient Appointments
              </a>
            </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default MiddleBanner;