import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/home/HomeBanner1.webp';
import Logo from '../assets/images/home/Logo2.webp';
import LogoMain from '../assets/images/home/LogoBanner.webp';
import Hospital from '../assets/images/home/Hospital.webp';
import Feedback from '../assets/images/home/Feedback.webp';
import Clock from '../assets/images/home/Clock.webp';
import {Link} from "react-router-dom";
import SingleService from '../components/common/SingleService';
import Icon1 from '../assets/images/home/Diabetes.webp';
import Icon2 from '../assets/images/home/Immunizations.webp';
import Icon3 from '../assets/images/home/Laboratory.webp';
import Icon4 from '../assets/images/home/Surgery.webp';
import Icon5 from '../assets/images/home/Preventative.webp';
import Icon6 from '../assets/images/home/Weight.webp';
import Icon7 from '../assets/images/home/EKG.webp';
import Icon8 from '../assets/images/home/Urgent.webp';
import Icon9 from '../assets/images/home/Education.webp';
import Icon10 from '../assets/images/home/Pediatric.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import Testimonials from '../components/Testimonials';
import HeaderIcon1 from '../assets/images/home/HeaderIcon1.png';
import HeaderIcon2 from '../assets/images/home/HeaderIcon2.png';
import HeaderIcon3 from '../assets/images/home/HeaderIcon3.png';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
        Home - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5">
          <div className="pb-4">
          <div className='d-block justify-content-center'>
          {/* <div className='d-flex justify-content-center'> */}
          <div className='align-self-center'>
          <img className='w-logo-title' src={LogoMain} alt="" />
          </div>
          <div className='px-2' />
          <div>
          <div className='fw-600 lh-sm text-white text-uppercase banner-title text-center'>
          {/* <div className='fw-600 lh-sm text-white text-uppercase banner-title text-start'> */}
           <span className='title-words'> Madison </span> <br />
           Primary Care
          </div>
          </div>      
          </div>

          <div className='py-2 py-lg-4' />
          <div className='py-2 py-lg-4' />

          <div className="row justify-content-center text-white fw-400 gy-4">
          <div className="col-md-3 col-lg-2">
            <div className="">
            <Link to='/contact-us' className="text-decoration-none text-white">  <img src={HeaderIcon1} alt="" className="w-icons" /> </Link>
            </div>
            <div className='py-2' />
            <div className="fs-ss-18">
            <Link to='/contact-us' className="text-decoration-none text-white"> Monday - Friday <br />
                7 am - 5 pm </Link>
            </div>
          </div>

          <div className="col-md-3 col-lg-2 mx-lg-5">
            <div className="">
            <a href='https://12216.portal.athenahealth.com/' target='_blank' className="fs-ss-18 text-decoration-none text-white"> <img src={HeaderIcon2} alt="" className="w-icons" /> </a>
            </div>
            <div className='py-2' />

            {/* <div className="fs-ss-18">
            <a href='https://12216.portal.athenahealth.com/' target='_blank' className="fs-ss-18 text-decoration-none text-white fw-500"> PATIENT PORTAL </a> <br /> 
            <a href='https://12216.portal.athenahealth.com/' target='_blank' className="text-decoration-none text-white"> 
            Messages . Refills <br />
            Records . Invoices <br />
            Payments </a>
            </div> */}

            <div className="fs-ss-18">
            <a href='https://12216.portal.athenahealth.com/' target='_blank' className="fs-ss-18 text-decoration-none text-white fw-500"> PATIENT PORTAL </a> <br /> 
            <a href='https://12216.portal.athenahealth.com/' target='_blank' className="text-decoration-none fs-ss-16 text-white"> 
            Messages <br /> Refills <br />
            Records <br /> Invoices <br />
            Payments </a>
            </div>
            
          </div>

          <div className="col-md-3 col-lg-2">
            <div className="">
            <a href='https://maps.app.goo.gl/b6vHT9M9eFNTYm39A' target='_blank' className="text-decoration-none text-white"> <img src={HeaderIcon3} alt="" className="w-icons2" /> </a>
            </div>
            <div className='py-2' />
            <a href='https://maps.app.goo.gl/b6vHT9M9eFNTYm39A' target='_blank' className="fs-ss-18 text-decoration-none text-white">
                400 Sun Temple Dr, <br className='d-block d-md-none' />
                Madison AL 35758
            </a>
          </div>
          </div>

          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-3 py-lg-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9 bg-white py-5 rounded-2">
    <div className='text-ss-secondary banner-title2 fw-500 lh-sm'>
      Primary Care Clinic Located in Madison, AL
    </div>
    <div className="py-3" />
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <img className='w-logo' src={Logo} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm '>
            Welcome to Madison <br className='' /> 
            <span className="text-ss-primary"> Primary Care </span>
            </div>
            <div className='pt-3'/>

            {/* <div className='fs-ss-16 fw-400 lh-para'>
            Living a healthy life is a journey of self-discovery and self-care, where each individual's path is unique. It involves prioritizing physical fitness, mental well-being, and emotional balance. At Madison Primary Care, your well-being is our priority. Our skilled doctors provide preventive, acute, and chronic care services, covering everything from immunizations to yearly health check-ups. Because your health journey is unique, we tailor our approach to meet your specific needs, focusing on prevention and empowering you with knowledge to take control of your health. Join us at Madison Primary Care for a fulfilling and healthy life journey. 
            </div> */}

            <div className='fs-ss-16 fw-400 lh-para'>
            Welcome to Madison Primary Care, established in 4/2011. MPC is a Family Medicine Practice, providing high quality Primary Care. Dr. Bodla is dedicated to providing compassionate care to help you and your loved ones live a healthier life. Dr. Bodla’s Clinical Team includes Board Certified Nurse Practitioners and Physician Assistants. Our MPC Providers believe in taking the time to get to know their patients, forming lasting relationships and becoming your partner in making informed healthcare decisions. 
            <div className="py-2" />
            Our Team practices evidence based medicine, providing the best care supported by medical research. Regardless of the reason for your visit, we will strive to incorporate preventive services and health promotion education into each visit. Our Providers believe in continuity of care during and after hospital stays. Dr. Bodla is credentialed at all Madison and Huntsville hospitals, should be alerted upon admission, and strives to see all patients post-discharge.
            </div>

          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 pt-lg-5 mt-lg-5 '/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
          <div className='text-center'>
            <img className='w-logo' src={Hospital} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-30 fw-600 fw-700 lh-sm'>
            Our Services
            </div>
            {/* <div className='pt-2'/> */}
            <div className='fs-ss-18 fw-500 lh-para'>
            Premium Health, Exceptional Care 
            </div>
          </div>
      <div className="row justify-content-evenly text-center pt-4 pt-lg-5 gy-4">
        <SingleService
        Image={Icon1}
        Name="Diabetes Care"
        Link_To="diabetes-care"
        />

        <SingleService
        Image={Icon2}
        Name="Immunizations"
        Link_To="immunizations"
        />

        <SingleService
        Image={Icon3}
        Name="Laboratory Services"
        Link_To="laboratory-services"
        />        

        <SingleService
        Image={Icon4}
        Name="Minor Skin Surgeries"
        Link_To="minor-skin-surgeries"
        />
        
        <SingleService
        Image={Icon5}
        Name="Preventive Care"
        Link_To="preventive-care"
        /> 

    <div className="d-block d-lg-none col-serv text-center bg-service py-2 shadow-ss-1 rounded-4 py-5">
    <Link className='text-decoration-none text-ss-secondary' to="/pediatric-care">
      <img className='w-services' src={Icon10} alt="Pediatric Care" />
      <div className='fw-400 pt-4 fs-ss-16 lh-sm'>
      Pediatric Care
      </div>
      </Link>
    </div> 

      </div>
      <div className="row justify-content-evenly text-center pt-4 pt-lg-5 gy-4">
        <SingleService
        Image={Icon6}
        Name="Weight Loss Management"
        Link_To="weight-loss-management"
        />

        <SingleService
        Image={Icon7}
        Name="EKG"
        Link_To="ekg"
        />

        <SingleService
        Image={Icon8}
        Name="Sick Visits & Urgent Care"
        Link_To="sick-visits-and-urgent-care"
        />        
        
    <div className="d-none d-lg-block col-serv text-center bg-service py-2 shadow-ss-1 rounded-4 py-5">
    <Link className='text-decoration-none text-ss-secondary' to="/pediatric-care">
      <img className='w-services' src={Icon10} alt="Pediatric Care" />
      <div className='fw-400 pt-4 fs-ss-16 lh-sm'>
      Pediatric Care
      </div>
    </Link>
    </div>  

    <div className="col-serv"/>

      </div>
    </section>
    
    <section className='pt-5 pt-lg-5 mt-lg-5 '/>

    <MiddleBanner/>

    <section className='pt-5 pt-lg-5 mt-lg-5 '/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
          <div className='text-center'>
            <img className='w-logo' src={Feedback} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-30 fw-600 fw-700 lh-sm'>
            Patient Reviews
            </div>
            <div className=''/>
            <div className='fs-ss-18 fw-500 lh-para px-3'>
            Patient Testimonials      
            </div>
          </div>

          <section className='pt-4'/>

      <section className="container-fluid text-center">
      <Testimonials/>
      </section>
      
      </section>

      <section className='pt-lg-5 pt-4'/>

      <section id='website-responsive' className="container-lg text-center text-lg-start text-ss-secondary">
      <div className="row justify-content-center pt-4 pt-lg-5 gy-4 align-items-center">
        <div className="col-12 col-lg-6 text-center">
        <img className='w-75 px-md-5 px-3' src={Clock} alt="" />
        </div>

        <div className="col-12 col-lg-5 ps-3">
        <div className='pt-3'>
        <div className='fs-ss-28 fw-600 lh-sm text-ss-primary'>
          Operating Hours
        </div>
        <div className='fs-ss-18 fw-500 lh-para pt-2'>
        <div className='fw-700'> MONDAY-FRIDAY: </div>
        7 AM - 5 PM

        <div className='pt-2'/>

        <div className='fw-700'> CLOSED FOR LUNCH DAILY: </div>
        12 PM - 1 PM 

        </div>
        </div>

        <div className='pt-3'>
        <div className='fs-ss-28 fw-600 lh-sm text-ss-primary'>
          Call Us
        </div>
        <div className='fs-ss-18 fw-500 lh-para d-lg-flex'>
          <div className='pt-2' >
          <div className='fw-700'> TELEPHONE: </div>
          <a className='text-ss-secondary text-decoration-none' href='tel:+12567745524' > 256-774-5524 </a>
          </div>

          <div className='px-lg-3' />

          <div className='pt-2'>
          <div className='fw-700'> FAX: </div>
          <a className='text-ss-secondary text-decoration-none' href='fax:+12567745523' > 256-774-5523 </a>
          </div>
        </div>
        </div>

        {/* <div className='pt-3'>
        <div className='fs-ss-28 fw-600 lh-sm text-ss-primary'>
        After-Hours Phone
        </div>
        <div className='fs-ss-18 fw-500 lh-para pt-2'>
        <div className='fw-700'> TELEPHONE: </div>
        <a className='text-ss-secondary text-decoration-none' href='tel:+18663880798' > 866-388-0798 </a>
        </div>
        </div> */}

        </div>
      </div>
      </section>

      <section className='pt-5 pt-lg-5 mt-lg-5 '/>

    <Footer/>
    </>
  );
}

export default Home;
