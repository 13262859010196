import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/ourservices/pediatrics-care/Banner.webp';
import Img1 from '../assets/images/ourservices/pediatrics-care/Img.webp';
import {Link} from "react-router-dom";
import HeartLogo from '../assets/images/aboutus/heart.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import FAQs from '../components/FAQs';
import { CircleFill } from 'react-bootstrap-icons';

const PediatricCare = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Pediatric Care - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-4">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Pediatric Care
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Pediatric Care at MPC
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Pediatric Care <br className='' /> 
            <span className="text-ss-primary"> Madison Primary Care </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            At Madison Primary Care, we understand that the health and well-being of your child are very important. Our pediatric services are designed to provide compassionate and comprehensive healthcare. From routine check-ups to specialized treatments, our dedicated team is committed to the health and wellbeing of your children. With our compassionate and gentle approach, children receive the care and attention they deserve. 
            </div>
            <div className="row justify-content-start pt-3 fs-ss-16 fw-500 lh-para">
            <div className="col-11 col-md-4 text-start">
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Comprehensive Management <br />
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Individualized Treatment Plans <br />
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Collaborative Care <br />
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Urgent Care and Sick Visits <br />
            </div>

            <div className="col-11 col-md-4 text-start">
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Health Maintenance Education <br />
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Wellness Activities <br />
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Disease Management and Follow-Up <br />
            </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-35 fw-700 lh-sm '>
              Comprehensive Pediatric <br />
        <span className='text-ss-primary'> Care for Children </span>
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        Our pediatric care services encompass a wide range of healthcare needs for children ages two and up. We offer wellness care, developmental assessments, vaccination plans, behavioral health assessments, illness management, and specialized care for chronic conditions. Our dedicated team helps nurture an environment where children feel comfortable and cared for, ensuring a positive healthcare experience for them and their families.
        </div>
        </div>
        </div>
        <div className="col-8 col-lg-5 text-center px-lg-5">
        <img className='px-lg-5 w-100' src={Img1} alt="" />
        </div>
      </div>
      </section>

    <section className='pt-5 mt-lg-5'/>

    <MiddleBanner/>

    <section className='pt-5 mt-lg-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-md-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Trust Your <br className='' /> 
            <span className="text-ss-primary"> Child's Health to Us </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            At Madison Primary Care, we pride ourselves on delivering compassionate and quality healthcare services for patients of all ages. Whether you seek care for your child or yourself, you can trust our experienced team to provide personalized and attentive service. Take the first step towards better health for your family by reaching out to book your appointment with us today. Your child's health is our priority at Madison Primary Care, and we're here to support you every step of the way. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          {/* <FAQs
          Title="When should I schedule my child's first pediatric appointment? "
          Para="It's recommended to schedule your child's first pediatric appointment within the first few weeks after birth. This initial visit allows the pediatrician to assess your baby's growth and development, guide feeding and care, and address any concerns you may have as new parents. "
          /> */}

          <FAQs
          Title="How often should my child have routine check-ups with the pediatrician? "
          Para="Routine check-ups are essential for monitoring your child's growth, development, and overall health. Follow-up visits may vary depending on your child's age and health needs. Young children may require more frequent visits, while older children may have annual check-ups. "
          />

          <FAQs
          Title="What vaccinations are recommended for children, and at what age should they receive them? "
          Para="Vaccinations are crucial for protecting children against serious diseases. The schedule recommended vaccination may vary depending on age, medical history, and current immunization status. Your pediatrician will provide guidance on which vaccines are recommended and when they should be administered. "
          />

          <FAQs
          Title="How can I help prepare my child for their pediatric appointment?  "
          Para="To help your child feel more comfortable and at ease during their pediatric appointment, it can be helpful to talk to them about what to expect, reassure them that the doctor is there to help them stay healthy, and bring along any comfort items, such as a favorite toy or blanket. "
          />

          <FAQs
          Title="What should I do if my child is experiencing a medical emergency or sudden illness?  "
          Para="In a medical emergency or sudden illness, seeking prompt medical attention for your child is essential. You can contact our clinic for guidance on what steps to take, or if the situation is life-threatening, call emergency services immediately. "
          />

          {/* <FAQs
          Title="Does your clinic offer after-hours care or telehealth appointments for pediatric patients?  "
          Para="Yes, our clinic offers after-hours care and telehealth appointments for pediatric patients. These services provide convenient access to medical care outside of regular office hours, allowing parents to seek assistance for their child's healthcare needs when necessary. "
          /> */}

          {/* <FAQs
          Title="How can I communicate with my child's pediatrician if I have questions or concerns outside of office hours?  "
          Para="Our clinic offers various communication channels for parents to reach their child's pediatrician with questions or concerns. Whether through phone calls, emails, patient portals, or telehealth platforms, we strive to ensure that parents can access the support and guidance they need to effectively care for their child's health. "
          /> */}
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default PediatricCare;
