import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/ourservices/minor-skin-surgeries/Banner.webp';
import Img1 from '../assets/images/ourservices/minor-skin-surgeries/Img.webp';
import {Link} from "react-router-dom";
import HeartLogo from '../assets/images/aboutus/heart.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import FAQs from '../components/FAQs';
import { CircleFill } from 'react-bootstrap-icons';

const MinorSkinSurgeries = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Minor Skin Surgeries - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-4">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Minor Skin Surgeries 
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Minor Skin Surgeries at MPC
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Minor Skin Surgeries <br className='' /> 
            <span className="text-ss-primary"> Madison Primary Care </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            Minor skin issues, while often benign, can lead to discomfort and impact overall well-being. At Madison Primary Care, we understand the impact of these concerns and perform minor skin procedures in a safe and professional environment. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
            <div className='pt-2'/>
        <div className='fs-ss-35 fw-700 lh-sm '>
        We offer
        <span className='text-ss-primary'> Services </span>
        </div>
        <div className='pt-3'/>
        <div className="fs-ss-16 fw-500 lh-para">
            <div className="">
              <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Skin Tag Removal <br/>
              <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Mole Removal <br/>
              <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Cyst Removal <br/>
              <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Wart Removal <br/>
              <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Biopsy Procedures <br/>
            </div>
            </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        Though usually harmless, minor skin conditions can be uncomfortable and make one feel self-conscious. We know how these worries may affect your self-esteem and general well-being. For this reason, we provide professional care for minor skin procedures performed by our team of qualified professionals in a secure setting. 
        </div>
        </div>
        </div>
        <div className="col-8 col-lg-5 text-center px-lg-5">
        <img className='px-lg-5 w-100' src={Img1} alt="" />
        </div>
      </div>
      </section>

    <section className='pt-5 mt-lg-5'/>

    <MiddleBanner/>
    
    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQs
          Title="Are minor skin surgeries painful? "
          Para="Minor skin surgeries are typically performed under local anesthesia, which minimizes discomfort during the procedure. You may experience mild discomfort or soreness afterward, but it is usually manageable with over-the-counter pain medication. "
          />

          <FAQs
          Title="How much time does recovery take following minor skin surgery? "
          Para="The recovery time following minor skin surgery depends on the nature and complexity of the procedure. Most patients can return to regular activities within a couple of days to a week. Your healthcare practitioner will provide specific post-operative instructions to facilitate healing and reduce complications."
          />

          <FAQs
          Title="After minor skin surgery, will scars form? "
          Para="Even while minor skin surgery can leave some scars, our highly trained healthcare providers go above and beyond to reduce scarring and achieve the best possible cosmetic outcomes. Adhering to post-operative maintenance guidelines can also lessen scar appearance over time. "
          />

          <FAQs
          Title="Can minor skin surgeries be performed on all skin types? "
          Para="Yes, minor skin surgeries can be performed on individuals of all skin types. Our experienced healthcare providers tailor the surgical approach to each patient's unique skin characteristics to ensure safe and effective outcomes. "
          />

          <FAQs
          Title="Do I need stitches after minor skin surgery? "
          Para="Stitches are needed depending on the procedure, incision site, or wound. Techniques such as sutures, staples, or adhesive strips close the incision and promote proper healing. Your healthcare provider will determine the most appropriate closure method based on the nature of the surgery and your individual needs. "
          />

          <FAQs
          Title="What should I expect during the minor skin surgery procedure? "
          Para="During minor skin surgery, you can expect local anesthesia to numb the treated area. The healthcare provider will then carefully perform the procedure, which may involve excising or removing the affected skin tissue, repairing injuries, or addressing specific dermatological concerns. The duration of the procedure varies depending on its complexity, but most minor surgeries are completed within a relatively short timeframe. Afterward, you'll receive instructions for post-operative care to ensure proper healing and minimize discomfort. "
          />

          <FAQs
          Title="What should I expect during a consultation for minor skin surgery? "
          Para="During your consultation, our healthcare provider will assess your skin concern, discuss treatment options, and address any questions or concerns you may have. They will also explain the surgery procedure, potential risks, and expected outcomes to ensure you are fully informed before proceeding. "
          />

          <FAQs
          Title="Is there anything I need to do to prepare for minor skin surgery? "
          Para="Your healthcare provider will provide specific pre-operative instructions before your minor skin surgery. This may include avoiding certain medications or skin care products, fasting if necessary, and arranging transportation to and from the appointment. Following these instructions helps ensure a smooth and successful procedure. "
          />
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default MinorSkinSurgeries;
