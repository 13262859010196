import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/ourservices/diabetics-care/Banner.webp';
import Img1 from '../assets/images/ourservices/diabetics-care/Img.webp';
import {Link} from "react-router-dom";
import HeartLogo from '../assets/images/aboutus/heart.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import FAQs from '../components/FAQs';
import { CircleFill } from 'react-bootstrap-icons';
import Bullets from '../components/common/Bullets';

const DiabetesCare = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Diabetes Care - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-4">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Diabetes Care
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Comprehensive Solutions for a Healthier You
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Empowering Lives, <br className='' /> 
            <span className="text-ss-primary"> Managing Diabetes Together </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            Living with diabetes demands personalized management and optimal treatment plans. At Madison Primary Care, we are committed to providing tailored care that adapts to your unique needs. Our skilled team is focused on helping you manage your condition effectively, improve your overall well-being, and helping you prevent the known complications of poorly managed Diabetes. 
            </div>
            <div className='fs-ss-16 fw-600 lh-para text-start pt-3'>
            What We Offer:  
            </div>
            <div className="row justify-content-start pt-3 fs-ss-16 fw-500 lh-para">
            <div className="col-11 col-md-6 text-start">
              <Bullets
              Title="Comprehensive Management:"
              Para="Full-spectrum diabetes care that addresses all aspects of your health."
              />

              <Bullets
              Title="Individualized Treatment Plans:"
              Para="Customized strategies designed specifically for your health objectives."
              />

              <Bullets
              Title="Collaborative Care:"
              Para="A team approach that ensures you have support at every step."
              />                          
            </div>

            <div className="col-11 col-md-6 text-start">
              <Bullets
              Title="Education and Support:"
              Para="Resources and guidance to empower you in your diabetes management journey."
              />   

              <Bullets
              Title="Monitoring and Follow-Up:"
              Para="Ongoing assessments to ensure optimal care and adjust treatments as needed."
              />  
            </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
            <div className='pt-2'/>
        <div className='fs-ss-35 fw-700 lh-sm '>
        Managing Diabetes Together
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        At Madison Primary Care, our diabetes care specialists are dedicated to providing personalized support to individuals managing diabetes. Our comprehensive management strategies combine medication, diet, exercise, and regular monitoring to empower our patients to lead healthy, fulfilling lives, despite the challenges of their condition.
        </div>
        <div className='pt-3'/>
        <div className="">
              <Bullets
              Title="Personalized Care Plans:"
              Para="Tailored to meet the unique needs of each patient."
              />

              <Bullets
              Title="Integrated Treatment Approaches:"
              Para="A holistic strategy that includes medication management, and health care promotion through dietary and exercise education."
              />

              <Bullets
              Title="Continuous Monitoring and Adjustments:"
              Para="Ensuring optimal control and timely modifications to treatment as needed."
              />   

              <Bullets
              Title="Educational Resources and Support:"
              Para="Helping patients understand their condition and how to manage it effectively."
              />                                        
            </div>

        </div>
        </div>
        <div className="col-8 col-lg-5 text-center px-lg-5">
        <img className='px-lg-5 w-100' src={Img1} alt="" />
        </div>
      </div>
      </section>

    <section className='pt-5 mt-lg-5'/>

    <MiddleBanner/>

    <section className='pt-5 mt-lg-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-md-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Take Control of <br className='' /> 
            <span className="text-ss-primary"> Your Diabetes Today </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Don't let diabetes control your life. At Madison Primary Care, we're here to support you every step of the way. We offer comprehensive management. We also provide personalized treatment plans. We have the expertise and resources to help you live well with diabetes. Schedule an appointment with us today and take control of your health. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQs
          Title="How often should I check my blood sugar levels? "
          Para="We recommend checking your blood sugar levels as your healthcare provider has advised, and always if experiencing signs or symptoms of high or low blood sugar."
          />

          <FAQs
          Title="What should I do if my blood sugar levels are too high or too low? "
          Para="If your blood sugar goes too high or too low, follow your healthcare provider's instructions for managing these situations. This may include adjusting your medication, consuming fast-acting carbohydrates for low blood sugar, or seeking immediate medical attention if necessary for any critical readings or symptoms."
          />

          <FAQs
          Title="Can I still enjoy my favorite foods while managing diabetes? "
          Para="Yes, you can still enjoy your favorite foods while managing diabetes. Focusing on portion control, balanced meals, and incorporating various foods into your diet is essential."
          />

          <FAQs
          Title="How can I prevent complications associated with diabetes? "
          Para={<>
          Preventing complications involves: 

          <ul>
          <li> Effectively managing your blood sugar levels. </li>
          <li> Staying physically active. </li>
          <li> Maintaining a healthy weight. </li>
          <li> Monitoring your blood pressure and cholesterol levels. </li>
          <li> Attending regular check-ups with your healthcare provider. </li>
          </ul>

          At Madison Primary Care, we can help you develop a comprehensive plan to reduce the risk of complications. 
          </>}
          />
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default DiabetesCare;
