import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/team/Banner.webp';
import Hospital from '../assets/images/home/Hospital.webp';
import Icon from '../assets/images/team/Icon.webp';
import SingleService from '../components/common/SingleService';
import Icon1 from '../assets/images/home/Diabetes.webp';
import Icon2 from '../assets/images/home/Immunizations.webp';
import Icon3 from '../assets/images/home/Laboratory.webp';
import Icon4 from '../assets/images/home/Surgery.webp';
import Icon5 from '../assets/images/home/Preventative.webp';
import Icon6 from '../assets/images/home/Weight.webp';
import Icon7 from '../assets/images/home/EKG.webp';
import Icon8 from '../assets/images/home/Urgent.webp';
import Icon9 from '../assets/images/home/Education.webp';
import Icon10 from '../assets/images/home/Pediatric.webp';
import Img1 from '../assets/images/team/5.webp';
import Img2 from '../assets/images/team/3.webp';
import Img3 from '../assets/images/team/7.webp';
import Img4 from '../assets/images/team/2.webp';
import Img5 from '../assets/images/team/4.webp';
import Img6 from '../assets/images/team/1.webp';
import Img7 from '../assets/images/team/7.webp';
import Img8 from '../assets/images/team/8.webp';
import Img9 from '../assets/images/team/7.webp';
import Img10 from '../assets/images/team/7.webp';
import Img11 from '../assets/images/team/7.webp';
import { Link } from 'react-router-dom';
import OneClinicalTeam from '../components/OneClinicalTeam';

const ClinicalTeam = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Clinical Team - Madison Primary Care
      </title>
    </Helmet>
    <Header/>

    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-4">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Clinical Team
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Expertise, Empathy, Excellence 
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 pt-lg-5 mt-lg-5 '/>

    <section id='website-responsive' className="container-lg text-ss-secondary px-md-5">
          <div className='text-center'>
          <img className='w-logo' src={Icon} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm '>
            Meet our Clinical Team <br className='' /> 
            <span className="text-ss-primary"> Providers </span>
            </div>
          </div>
          <div className="row justify-content-center align-items-center gy-5 pt-lg-5 pt-4 px-lg-5">

          <OneClinicalTeam
          Title1="Adeel Bodla"
          Title2="- MD"
          Title3="Board Certified"
          BackDrop1="#staticBackdrop"
          BackDrop2="staticBackdrop"
          ProfileImg={Img1}
          Para="Dr. Bodla completed his Family Medicine internship at Charles Drew University of Medicine and Science, Los Angeles, and his residency at the University of Alabama, Birmingham. He has served as an Assistant Professor at the University of California-Irvine, specializing in family medicine.With a strong belief in fostering enduring patient relationships, Dr. Bodla aims to impact health and wellness positively. He enjoys leisure time with his wife and three children, engaging in tennis and golf, and avidly following sporting events. Dr. Bodla eagerly anticipates meeting your family's healthcare needs. " 
          />

          <OneClinicalTeam
          Title1="Lindsey Guill"
          Title2="- PA"
          Title3="Board Certified"
          BackDrop1="#staticBackdrop1"
          BackDrop2="staticBackdrop1"
          ProfileImg={Img2}
          Para="Lindsey Guill, a Tennessee native, earned her Bachelor of Science in Molecular and Cellular Biology from Harding University. Her passion for healthcare was ignited during her time in Zambia, Africa, where she focused on medical missions, leading her to complete a Master of Science in Physician Assistant Studies at Harding University. Certified by the NCCPA and licensed by the ALBME, Lindsey has dedicated three years to practicing Family Medicine in Madison, AL. She treasures the privilege of caring for her patients. Happily married to her college sweetheart, Lindsey enjoys traveling, hiking, playing tennis, and cherishing moments with friends and family."          
          />

          <OneClinicalTeam
          Title1="Alexandra Maner"
          Title2="- PA"
          Title3="Board Certified"
          BackDrop1="#staticBackdrop2"
          BackDrop2="staticBackdrop2" 
          ProfileImg={Img8}
          Para=""         
          />

          <OneClinicalTeam
          Title1="Katherine Koshofer"
          Title2="- PA"
          Title3="Board Certified"
          BackDrop1="#staticBackdrop3"
          BackDrop2="staticBackdrop3"
          ProfileImg={Img4}
          Para="Katherine Koshofer, originally from Oxford, MS, graduated with a bachelor’s in biology from Mississippi College in 2001. Driven by a passion for teaching and learning in healthcare, she excelled in Physician Assistant school at Emory University, graduating with high honors in 2006. With 11 years of experience in Family Medicine in the greater Atlanta area and since 2018 in Madison, Katie values the connection she builds with her patients. She empowers patients to advocate for their health through daily choices. With her husband and three children, Katie relocated from Atlanta to Madison to establish roots in a close-knit community. Beyond her medical career, Katie cherishes moments spent with her family when not at work."          
          />

          <OneClinicalTeam
          Title1="Kelly Baugher"
          Title2="- FNP"
          Title3="Board Certified"
          BackDrop1="#staticBackdrop4"
          BackDrop2="staticBackdrop4"
          ProfileImg={Img5}
          Para="Kelly Baugher, originally from Huntsville, AL, pursued her passion for healthcare after achieving a Communication Arts degree and becoming a published fiction novelist. She transitioned to nursing, earning a Bachelor of Science in Nursing from the University of Alabama in Huntsville. Kelly started her career as a NICU RN at Huntsville Hospital, specializing in care for extremely premature infants. Driven by her commitment to patient care, she pursued a Master of Science in Nursing, becoming a certified Family Nurse Practitioner. With experience in urgent care, adult primary care, and pediatrics, Kelly thrives on establishing enduring relationships with patients of all ages. Outside of her medical career, Kelly enjoys traveling with her family, particularly to Costa Rica and indulges in hobbies like reading, writing, photography, running, and volunteering."          
          />

          <OneClinicalTeam
          Title1="Kristy Williams"
          Title2="- FNP"
          Title3="Board Certified"
          BackDrop1="#staticBackdrop5"
          BackDrop2="staticBackdrop5"
          ProfileImg={Img6}
          Para="Kristy Williams, originally from Florence, AL, began her healthcare journey by earning a Bachelor of Science in Nursing from The University of North Alabama. Her career started at UAB Hospital in Birmingham, where she gained valuable experience in infectious disease and hospitalist units, sparking her passion for learning. Driven to advance her practice, Kristy pursued graduate studies at the University of Alabama in Huntsville while working as an RN at Huntsville Hospital's orthopedic units. She graduated with a Master of Science in Nursing and became a Board-Certified Family Nurse Practitioner. With experience in urgent care and family practice across various age groups, Kristy finds fulfillment in meeting her patients' healthcare needs at Madison Primary Care. Outside work, she treasures time with her husband and children, indulging her love for football, basketball, baseball, and hockey. Her favorite retreat is the beach. "          
          />          

          <OneClinicalTeam
          Title1="Fakhra Ahmad Bodla"
          Title2="- FNP"
          Title3="Board Certified"
          BackDrop1="#staticBackdrop6"
          BackDrop2="staticBackdrop6"
          ProfileImg={Img7}
          Para="A Pasadena native, Fakhra Ahmad Bodla earned her bachelor’s in nursing from the University of Southern California. With a focus on maternal-child health, she specialized as a Pediatric nurse before advancing her education at the University of California, Los Angeles. In 2003, Fakhra earned her master’s in nursing and became a certified Family Nurse Practitioner. Her extensive experience spans urgent care, adult medicine, pediatrics, and gynecology, shaping her into a compassionate and adept provider. Committed to being a supportive partner in her patients' healthcare decisions, Fakhra strongly advocates for health promotion. Outside of work, she relishes time with her husband and three children."          
          />
          
          <div className='fw-600 lh-sm text-center text-ss-primary fs-ss-30 pt-4'>
          Administrative Team
          </div>

          {/* <OneClinicalTeam
          Title1="Holly Balsamo"
          Title2="RN"
          BackDrop1="#staticBackdrop7"
          BackDrop2="staticBackdrop7"    
          ProfileImg={Img8}      
          /> */}

          <OneClinicalTeam
          Title1="Mackenzie Romero"
          Title2=""
          Title3={<> <div className="text-ss-primary"> Office Manager </div> </>}
          BackDrop1="#staticBackdrop10"
          BackDrop2="staticBackdrop10"  
          ProfileImg={Img11}        
          />

          <OneClinicalTeam
          Title1={<> Alexis Chchipa </>}
          Title2=""
          Title3={<> <div className="text-ss-primary"> Front Office Lead </div> </>}
          BackDrop1="#staticBackdrop9"
          BackDrop2="staticBackdrop9"  
          ProfileImg={Img10}        
          />
          
          <OneClinicalTeam
          Title1="Melissa Montalvo"
          Title2="- MA"
          Title3="Clinical Lead"
          BackDrop1="#staticBackdrop8"
          BackDrop2="staticBackdrop8"    
          ProfileImg={Img9}      
          />

          <OneClinicalTeam
          Title1="Shamira Smith"
          Title2="- MA, BS"
          Title3="Billing Manager"
          BackDrop1="#staticBackdrop7"
          BackDrop2="staticBackdrop7"  
          ProfileImg={Img11}        
          />

          </div>        
    </section>

    <section className='pt-5 pt-lg-5 mt-lg-5 '/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
          <div className='text-center'>
            <img className='w-logo' src={Hospital} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-30 fw-600 fw-700 lh-sm'>
            Our Services
            </div>
            {/* <div className='pt-2'/> */}
            <div className='fs-ss-18 fw-500 lh-para'>
            Premium Health, Exceptional Care 
            </div>
          </div>

          <div className="row justify-content-evenly text-center pt-4 pt-lg-5 gy-4">
        <SingleService
        Image={Icon1}
        Name="Diabetes Care"
        Link_To="/diabetes-care"
        />

        <SingleService
        Image={Icon2}
        Name="Immunizations"
        Link_To="/immunizations"
        />

        <SingleService
        Image={Icon3}
        Name="Laboratory Services"
        Link_To="/laboratory-services"
        />        

        <SingleService
        Image={Icon4}
        Name="Minor Skin Surgeries"
        Link_To="/minor-skin-surgeries"
        />
        
        <SingleService
        Image={Icon5}
        Name="Preventive Care"
        Link_To="/preventive-care"
        /> 

    <div className="d-block d-lg-none col-serv text-center bg-service py-2 shadow-ss-1 rounded-4 py-5">
    <Link className='text-decoration-none text-ss-secondary' to="/pediatric-care">
      <img className='w-services' src={Icon10} alt="Pediatric Care" />
      <div className='fw-400 pt-4 fs-ss-16 lh-sm'>
      Pediatric Care
      </div>
      </Link>
    </div> 

      </div>
      <div className="row justify-content-evenly text-center pt-4 pt-lg-5 gy-4">
        <SingleService
        Image={Icon6}
        Name="Weight Loss Management"
        Link_To="/weight-loss-management"
        />

        <SingleService
        Image={Icon7}
        Name="EKG"
        Link_To="/ekg"
        />

        <SingleService
        Image={Icon8}
        Name="Sick Visits & Urgent Care"
        Link_To="/sick-visits-and-urgent-care"
        />        
        

    <div className="d-none d-lg-block col-serv text-center bg-service py-2 shadow-ss-1 rounded-4 py-5">
    <Link className='text-decoration-none text-ss-secondary' to="/pediatric-care">
      <img className='w-services' src={Icon10} alt="Pediatric Care" />
      <div className='fw-400 pt-4 fs-ss-16 lh-sm'>
      Pediatric Care
      </div>
    </Link>
    </div>  

    <div className="col-serv"/>

      </div>          
    </section>

    <section className='pt-5 pt-lg-5 mt-lg-5 '/>

    <Footer/>
    </>
  );
}

export default ClinicalTeam;
