import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/ourservices/weight-loss/Banner.webp';
import Img1 from '../assets/images/ourservices/weight-loss/Img.webp';
import {Link} from "react-router-dom";
import HeartLogo from '../assets/images/aboutus/heart.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import FAQs from '../components/FAQs';
import { CircleFill } from 'react-bootstrap-icons';

const WeightLossManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Weight Loss Management - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-4">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Weight Loss Management
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Weight Loss Management at MPC
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Weight Loss Management <br className='' /> 
            <span className="text-ss-primary"> Madison Primary Care </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            Focused weight management appointments are dedicated appointments that are not combined with routine office visits or annual physical appointments. Maintaining a healthy lifestyle will impact your overall well-being and quality of life. At Madison Primary Care, we understand individual challenges in achieving and sustaining their weight loss goals. We offer comprehensive weight loss management services for your unique needs, designed to support you in adopting sustainable lifestyle changes for long-term success. 
            </div>
            <div className="row justify-content-start pt-3 fs-ss-16 fw-500 lh-para">
            <div className="col-11 col-md-4 text-start">
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Individualized Assessment <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Nutritional Counseling  <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Behavioral Support  <br/>
            </div>

            <div className="col-11 col-md-4 text-start">
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Physical Activity Recommendations  <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Medical Interventions  <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/>  Medication Management <br/>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
            <div className='pt-2'/>
        <div className='fs-ss-35 fw-700 lh-sm '>
          Transform Your Life and   <br />
        <span className='text-ss-primary'> Achieve Your Goals </span>
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        Our weight loss management program supports making lasting changes to your lifestyle. We believe in taking a holistic approach to weight loss, addressing the medical, physical, emotional, and behavioral aspects that impact your weight loss journey. With our compassionate team, you can overcome obstacles, break through plateaus, and confidently achieve your weight loss goals.  
        </div>
        </div>
        </div>
        <div className="col-8 col-lg-5 text-center px-lg-5">
        <img className='px-lg-5 w-100' src={Img1} alt="" />
        </div>
      </div>
      </section>

    <section className='pt-5 mt-lg-5'/>

    <MiddleBanner/>

    <section className='pt-5 mt-lg-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-md-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Achieving and Maintaining <br className='' /> 
            <span className="text-ss-primary"> a Healthy Weight </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Madison Primary Care can help optimize your weight loss goals to enhance your general health and well-being. Together, we can develop a customized plan to assist you in achieving your weight loss goals, and maintaining a healthy weight. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQs
          Title="What is the anticipated weight loss amount for your program? "
          Para="Weight loss results vary based on initial weight, program adherence, and metabolic rate. At Madison Primary Care our goal is to help you lose weight gradually, sustainably, and at a safe and beneficial pace for your body. "
          />

          <FAQs
          Title="Is your weight loss program suitable for individuals with medical conditions? "
          Para="Our weight loss program is designed to accommodate individuals with various medical conditions. Our healthcare providers will help develop your weight loss strategy in consideration of your overall health and medical conditions."
          />

          <FAQs
          Title="Do I have to follow a specific diet plan?  "
          Para="Our approach focuses on helping you develop a balanced, sustainable eating pattern that aligns with your preferences and lifestyle to achieve long-term success. "
          />

          <FAQs
          Title="Do you offer support for maintaining weight loss after reaching my goal?  "
          Para="Some patients benefit from ongoing support and follow-up care to maintain your weight loss achievements long-term. Our team can work with you to develop strategies for overcoming challenges and sustaining your progress over time. "
          />

          <FAQs
          Title="Are weight loss medications or supplements part of your program?  "
          Para="Sometimes, weight loss medications or supplements may be recommended as part of your treatment plan. However, these interventions are typically used with dietary and lifestyle modifications based on individual needs and preferences."
          />

          <FAQs
          Title="How often will I need to meet with my healthcare provider during the program?  "
          Para="The frequency depends on your progress and needs. Initially, you may have more frequent visits to monitor and adjust your treatment plan. As you progress, appointments may be scheduled less frequently.  "
          />

          <FAQs
          Title="Can I participate in your weight loss program if I have previously tried other methods without success?  "
          Para="Of course! Whether you're just getting started or have tried other methods in the past, our weight loss program is tailored to support patients throughout their journey. Together, we will establish reasonable goals, and create a customized strategy for success. "
          />
        </div>
      </div>
    </section>

    <section className='pt-5 mt-lg-5'/>

    <Footer/>
    </>
  );
}

export default WeightLossManagement;
