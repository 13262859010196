import React from 'react';
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom";
import NavBarLink from './NavBarLink';

const NavBarLink2 = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
    <li className="nav-item fw-500 fs-ss-links right-menu d-block d-lg-none">
          <div class="btn-group dropdown">
          <Link className={splitLocation[1] === `${props.Link_To}` ? `nav-link hover-green text-ss-green ${props.Border}` : `nav-link hover-green text-ss-secondary ${props.Border}`} to={`/${props.Link_To}`}> {props.Name} </Link>
            <Link class="btn px-4 dropdown-toggle dropdown-toggle-split border-0 text-black" data-bs-toggle="dropdown-menu" aria-expanded="false">
              <span class="visually-hidden">Toggle Dropdown</span>
              </Link>
          </div>
          <ul className="dropdown-menu hover1 fs-ss-links w-content">
            {props.DropdownMenu}
          </ul>
    </li>
    
    <li className="nav-item fw-500 fs-ss-links right-menu d-none d-lg-block">
          <Link className={splitLocation[1] === `${props.Link_To}` ? `nav-link dropdown-toggle nav-link hover-green text-ss-green ${props.Border}` : `nav-link dropdown-toggle hover-green text-ss-secondary ${props.Border}`} to={`/${props.Link_To}`}> {props.Name} </Link>
          <ul className="dropdown-menu hover1 fs-ss-links w-content">
            {props.DropdownMenu}
          </ul>
    </li>
    </>
  )
}

export default NavBarLink2