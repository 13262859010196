import React from 'react';
import {Routes, Route} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import '../src/assets/css/style.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Pages
import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs';
import DiabetesCare from './pages/DiabetesCare';
import Privacy from './pages/Privacy';
import Blogs from './pages/Blogs';
import TermsAndConditions from './pages/Terms';
import HealthLinks from './pages/HealthLinks';
import ClinicalTeam from './pages/ClinicalTeam';
import Immunizations from './pages/Immunizations';
import Laboratory from './pages/Laboratory';
import OurServices from './pages/OurServices';
import MinorSkinSurgeries from './pages/MinorSkinSurgeries';
import PreventiveCare from './pages/PreventiveCare';
import WeightLossManagement from './pages/WeightLossManagement';
import EKG from './pages/EKG';
import SickVisitsUrgentCare from './pages/SickVisitsUrgentCare';
// import HighEducationPromotion from './pages/HighEducationPromotion';
import PediatricCare from './pages/PediatricCare';
import CCM from './pages/CCM';
import ACO from './pages/ACO';
import Error404 from './pages/Error404';

const App = () => {
  return (
    <>
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about-us" element={<AboutUs/>} />
        <Route path="/clinical-team" element={<ClinicalTeam/>} />
        <Route path="/contact-us" element={<ContactUs/>} />
        <Route path="/diabetes-care" element={<DiabetesCare/>} />
        <Route path="/minor-skin-surgeries" element={<MinorSkinSurgeries/>} />
        <Route path="/preventive-care" element={<PreventiveCare/>} />
        <Route path="/weight-loss-management" element={<WeightLossManagement/>} />
        <Route path="/ekg" element={<EKG/>} />
        <Route path="/*" element={<Error404/>} />
        <Route path="/sick-visits-and-urgent-care" element={<SickVisitsUrgentCare/>} />
        {/* <Route path="/health-education-and-promotion" element={<HighEducationPromotion/>} /> */}
        <Route path="/pediatric-care" element={<PediatricCare/>} />
        <Route path="/immunizations" element={<Immunizations/>} />
        <Route path="/laboratory-services" element={<Laboratory/>} />
        <Route path="/blogs" element={<Blogs/>} />
        <Route path="/privacy-policy" element={<Privacy/>} />
        <Route path="/health-links" element={<HealthLinks/>} />
        <Route path="/services" element={<OurServices/>} />
        <Route path="/medicare-ccm" element={<CCM/>} />
        <Route path="/medicare-aco" element={<ACO/>} />
        </Routes>
    </>
  );
}

export default App;
